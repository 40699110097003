.border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
medsquire-calendar {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background: #eeeeee;
  width: 100%;
  border-bottom: 2px #cccccc solid;
}
div.teste {
  width: 100%;
  background: #2875c7;
  height: 40px;
  color: blue;
}
medsquire-calendar > div.header {
  width: 100%;/*100%;*/
  background: #80b107;
  height: 40px;
  color: white;
}
medsquire-calendar > div.header > * {
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  vertical-align: middle;
}
medsquire-calendar > div.header > i {
  /*float: left;*/
  width: 40px;
  font-size: 2em;
  font-weight: bold;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;
}
medsquire-calendar > div.header > i.previous {
  text-align: center;
  float: left;
  width: 15%;
}
medsquire-calendar > div.header > i.next {
  text-align: center;
  float: right;
  width: 15%;
}
medsquire-calendar > div.header > span {
  /*float: left;*/
  width: 70%;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /*padding-left: 50px;
  margin-left: -40px;*/
  text-align: center;
  /*padding-right: 40px;*/
  color: inherit;
}
medsquire-calendar > div.week {
  width: 100%;
  border-top: solid 1px #cccccc;
}
medsquire-calendar > div.week:first-child {
  border-top: none;
}

medsquire-calendar > div.week > span.headerday {
  width: 13.1%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-size: 0.75em;
  text-align: center;
  height: 30px;
  line-height: 30px !important;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: black;
}

medsquire-calendar > div.week > span.day {
  width: 14.2857%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-size: 1em;
  text-align: center;
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: black;
}

medsquire-calendar > div.week > span.day:first-child {
  border-left: none;
}
medsquire-calendar > div.week > span.day.today {
  background-color: #f7f7f7;
  font-weight: bold;
}
medsquire-calendar > div.week > span.day.is-past.day.has-event {
  background: #c0c0c0;
}
medsquire-calendar > div.week > span.day.is-past.day.has-event.selected,
medsquire-calendar > div.week > span.day.is-past.selected {
  background: #999999;
}
medsquire-calendar > div.week > span.day.different-month {
  color: #C0C0C0;
}
medsquire-calendar > div.week > span.day.selected,
medsquire-calendar > div.week > span.day.today.selected,
medsquire-calendar > div.week > span.day.selected.has-event {
  background: #6c9605;
  color: white;
}
medsquire-calendar > div.week > span.day.has-event {
  background: #80b107;
  color: white;
}
medsquire-calendar > div.week.names > span {
  color: #2875c7;
  font-weight: bold;
}

medsquire-calendar > div.week > span.day.has-event.has-alert {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}

medsquire-calendar > div.week > span.day.has-event.has-action {
  background-image: url("assets/img/orange-dot.png") !important;
}

medsquire-calendar > div.week > span.day.has-event.has-contested {
  background-image: url("assets/img/red-dot.png") !important;
}

medsquire-calendar > div.week > span.day.has-event.has-action.has-contested {
  background-image: url("assets/img/red-orange-dot.png") !important;
}
