.tabbed-slidebox .slider {
  height: 100%;
}

.tabbed-slidebox.btm .slider {
  margin-bottom: -50px;
}

.tabbed-slidebox .slider-slides {
  width: 100%;
}

.tabbed-slidebox .slider-slide {
  padding-top: 0px;
}

.tabbed-slidebox .tsb-icons {
  text-align: center;
  padding: 0 0 7px 0;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #cccccc;
}

.tabbed-slidebox .tsb-ic-wrp {
  display: flex;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  /* For Safari 3.1 to 6.0 */
  -webkit-transform: translate3d(0, 0, 0);
}

.tabbed-slidebox .tsb-icons a {
  display: inline-block;
  width: 54px;
  font-size: 2.5em;
  color: rgba(0, 0, 0, 0.3);
  -webkit-transform: translate3d(0, 8px, 0);
}

.tabbed-slidebox .tsb-icons a.active {
  color: rgba(0, 0, 0, 1);
  background-color: rgb(221, 221, 221);
}

.tabbed-slidebox .tabbed-slidebox {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.tabbed-slidebox .tsb-icons:after {}

.tsb-hscroll.scroll-view {
  white-space: nowrap;
  margin: 0 auto;
}

.tsb-hscroll.scroll-view .scroll-bar {
  visibility: hidden;
}

.tsb-hscroll.scroll-view .scroll.onscroll {
  -webkit-transition: -webkit-transform 0.3s;
  /* For Safari 3.1 to 6.0 */
}

.tabbed-slidebox .tsb-icons .scroll a {
  width: auto;
  font-size: 1.3em;
  line-height: 1.3em;
  text-decoration: none;
  margin: 0 5px;
  border: 1px solid rgb(221, 221, 221);
  border-bottom: 3px solid transparent;
  padding: 3px 9px;
  border-radius: 5px 5px 0px 0px;
}

.tabbed-slidebox .tsb-icons .scroll a.active {}
