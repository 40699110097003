.offer-item {
    padding: 0;
    border: 2px solid #02a7e4;
    box-sizing: border-box;
}

.offer-item a.item-content {
    padding: 0 !important;
    padding-right: 40px !important;
}

.offer-item a.item-content {
    background-color: #02a7e4 !important;
    color: white !important;
}

.offer-item a.item-content .icon {
    right: 3px !important;
}

.offer-title {
    background-color: #02a7e4 !important;
    color: white !important;
}

.offer-block .item-divider {
    background-color: #d9f3fd;
    border-color: #02a7e4;
}